.LocationSelector_Shiftright {
  margin-left: 32px;
}

.LocationSelector_Selectbox {
  height: 36px;
  width: 288px;
  margin-top: 8px;
  padding-left: 12px;
  border: 1px solid #CED0DA;
  border-radius: 4px;
  background: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
}

.LocationSelector_Inputbox {
  height: 36px;
  width: 288px;
  border-radius: 4px;
  border: 1px solid #DFE3E9;

  padding-left: 12px;
  padding-right: 12px;
  background-position: right 9px center;
  outline: none;
}

.LocationSelector_Inputbox:hover {
  border: 1px solid #CED0DA;
  background-color: #FFFFFF;
}
.LocationSelector_Inputbox:focus {
  border-color: #4758BE;
  background-color: #FFFFFF;
}
