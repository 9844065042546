.ReportsExecute__outer {
  display: flex;
  justify-content: center;
  background-color: #fbfbfc;
}

.ReportsExecute__contentbox {
  width: 50vw;
  height: fit-content;
  margin: 20px;
}

.ReportsExecute__header {
  font-size: 16px;
  font-weight: 400;
}

.ReportsExecute__controlbox {
  min-height: 300px;
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #e6eaee;
  color: #7f8fa4;
}

.ReportsExecute__selectedCompany {
  margin-top: 16px;
}

.ReportsExecute__title {
  margin-top: 20px;
  margin-bottom: 8px;
}

.ReportsExecute__reportTypeCheckbox {
  margin-top: 4px;
  margin-bottom: 4px;
}

.ReportsExecute__reportTypeName {
  margin-left: 8px;
}

.ReportsExecute__button {
  margin-top: 20px;
}

.ReportsExecute__helperText {
  padding: 1em;
  background-color: white;
  margin: 8px 0px;
  border-radius: 4px;
}

.ReportsExecute__resend {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ReportsExecute__selectHour {
  margin-top: 20px;
}
.ReportsExecute__overrideMailDrop {
  margin-top: 20px;
  height: 34px;
  border: 1px solid #ced0da;
  border-radius: 4px;
  background-color: #ffffff;
  color: rgba(49, 49, 49, 0.8);
  width: 20em;
  padding-left: .5em;
  padding-right: .5em;
}
.ReportExecute__errors {
  color: red;
  padding-top: .5em;
  text-align: right;
}
