@import "common/css/_breakpoints.scss";

.SearchBox {
  height: 36px;
  width: 40px;
  border: 1px solid transparent;
  border-radius: 4px;

  padding: 0em 12px 0em 12px;
  background: url('../../../images/dashboard/w_search.svg') no-repeat ;
  background-position: right 9px center;
  transition: all .5s ease-in-out;
  outline: none;

  font-size: 16px;
}

.SearchBox_Hovered {
  border: 1px solid #DFE3E9;
  background-color: #FFFFFF;
}
.SearchBox_Focused {
  border-color: #4758BE;
  background-color: #FFFFFF;
}

.SearchboxSelector__Select{
  font-family: 'FontAwesome', 'Rubik', sans-serif;
}

@media all and (max-width: $break-mobile) {
  .SearchBox {
    display: none;
  }
}
