.MultiSearchBoxSelector {
  display:flex;
  flex-direction: column;
  /*margin-left: 18px;*/
  /*width: 260px;*/
  /*width: 400px;*/
}


.MultiSearchBoxSelector__input {
  /*width: 240px;*/
  width: 100%;
  height: 36px;
  padding-left: 36px;
  border: 1px solid #CED0DA;
  border-radius: 4px 0px 0px 0px;
  background-color: #FFFFFF;
  background-image: url('../../../images/dashboard/w_search.svg');
  background-repeat: no-repeat;
  background-position: left 8px center;
  outline: none;
  font-size: 16px;
}

.MultiSearchBoxSelector__select--outer {
  /* width: inherit; */
  display: flex;
}
.MultiSearchBoxSelector__select--outer select {
  font-family: 'FontAwesome', 'Rubik', sans-serif;
}

.MultiSearchBoxSelector__Select {
  flex-grow: 1;
  /* width: inherit; */
  border: 1px solid #CED0DA;
  border-radius: 0px 0px 4px 4px;
  background-color: #FFFFFF;
  color: rgba(49,49,49,0.8);

  -webkit-appearance:button;
  -moz-appearance:button;
  appearance:button;

  margin: 0px auto 12px 0px;
  overflow: hidden;
  overflow-y: auto;
}

.MultiSearchBoxSelector__nothingFound{
  border: 1px solid red;
  padding: 10px;
  margin-top: 4px;
}

.MultiSearchBoxSelector__option {
  padding: 12px;
}
.MultiSearchBoxSelector__option:hover {
  background-color:#DFE3E9;
}
.MultiSearchBoxSelector__option--none {
  display: none;
}

.MultiSearchBoxSelector__input--outer {
  display:flex;
}

.MultiSearchBoxSelector__input--outer > img{
  border: 1px solid #CED0DA;
  border-radius: 0px 4px 4px 0px;
  background-color: #FFFFFF;
  padding: 8px;
}
