.BusySpinner__base {
  border: 1px solid transparent;
  border-radius: 50%;
}

.BusySpinner__refresh {
  transition: all ease 0.6s;
  cursor: pointer;
}

.BusySpinner__refresh:active {
  opacity: 0.6;
  background-color: #ddd;
  transform: rotate(180deg);
}

.BusySpinner__refresh:hover {
  border: 1px solid #364760;
}
