.wwa_checkbox_and_label {
  display:flex;
  /* margin-bottom: 16px; */
  margin-right: 50px;
}

.wwa-checkbox.item-selected {
}

.wwa-checkbox {
  margin-right: 21px;
  height: 15px;
  width: 15px;
  color : white;
  border: solid 1px #4758BE;
	background-color:#4758BE;
  border-radius: 2px;
  margin-right: 14px;
}
.wwa-checkbox span{
  position: relative;
  top: -3px;
}

.wwa_checkbox_label {  
  margin-top:6px;
  margin-left:12px;
  height: 15px;
  color: #354052;
  font-size: 13px;
  line-height: 15px;
}

.wwa-radio {
	display: none;
}

.wwa-radio + label {
	background-color: #FFFFFF;
	border: 2px solid #4758BE;
	padding: 6px;
	border-radius: 50px;
	display: inline-block;
	position: relative;
  top: 9px;
  margin-right: 15px
}

.wwa-radio:checked + label:after {
	content: ' ';
	width: 6px;
	height: 6px;
	border-radius: 50px;
	border: 4px solid #4758BE;
	position: absolute;
	top: 2px;
	left: 2px;
	background: #FFFFFF;
}

.wwa-radio:checked + label {
	background-color: #ffffff;
	border: 2px solid #4758BE;
}

.wwa-radio + label:active, .wwa-radio:checked + label:active {
}

.wwa_radio_label {  
  height: 15px;
  width: 24px;
  color: #354052;
  font-size: 13px;
  line-height: 15px;
  /*margin-right:35px;*/
}

.wwa__form__label {
  opacity: 0.6;
}

.wwa__select {
  border: 1px solid #CED0DA;
  border-radius: 4px;
  background-color: #FFFFFF;
  color: rgba(49,49,49,0.8);
  padding: 4px 10px;
  padding-right: 34px;
  height:32px;
  
  /* exchange browser specific triangle with one that is identical across browsers */
  /* double arrow up/down: */
  /* background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=) no-repeat 95% 50%; */
  /*just one arrow down: */
  /* background: url(data:image/svg+xml;base64,PHN2ZyBkYXRhLW5hbWU9IkxheWVyIDEiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDQuOTUgMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8ZGVmcz4KICA8c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT4KIDwvZGVmcz4KIDx0aXRsZT5hcnJvd3M8L3RpdGxlPgogPHBvbHlnb24gY2xhc3M9ImNscy0yIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSguMDQyMzczIC0uOTc0NTgpIiBwb2ludHM9IjMuNTQgNS4zMyAyLjQ4IDYuODIgMS40MSA1LjMzIiBmaWxsPSIjNDQ0Ii8+Cjwvc3ZnPgo=) no-repeat 95% 50%;
  appearance: none; */

  background-image: url('../../images/dashboard/dropdown-arrow.svg');
  background-position: 90% center;
  background-repeat: no-repeat;
  appearance: none;
  cursor: pointer;

  font-size: 16px;

}
