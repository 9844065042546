$imgMaxWidth: 1024px;
$imgMaxHeight: 1024px;
$imgMinHeight: 1024px;

.ImageCrop__Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #ddd;
}

.ImageCrop__Hint {
  margin: 0.2em 2em 0.2em;
}

.ImageCrop__Alert {
  grid-area: ict;
}

.ImageCrop__FileChooser {
  margin: 2em;
  cursor: pointer;
  grid-area: icc;
}
.ImageCrop__FileChooser2 {
  margin: 0.5em;
  cursor: pointer;
}
.ImageCrop__FileChooser > input {
  cursor: pointer;
}

.ImageCrop__CropContainer {
  border: 1px solid #ddd;
  max-width: $imgMaxWidth;
  margin-top: 1em;
}

.ImageCrop__PreviewHint {
  margin: 1em 0em 0em;
}

.ImageCrop__PreviewImg {
  max-height: $imgMaxHeight;
}

.ImageCrop__CroppedImage {
  border: 2px solid #ddd;
  margin: 1em;
}

.ImageCrop__CroppedImage > img {
  // width: 300px;
  // height: 169px;
}

.ImageCrop__ResetButton {
  margin: 0.5em;
  color: #000000;
}

.ImageCrop__Buttons {
  display: flex;
  // margin-top: 1em;
  margin-bottom: 1em;
}

.ImageCrop__Alert {
  // margin-top: 1em;
}
