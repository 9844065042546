.TableHead {
  min-height: 56px;
  padding: 8px;
  padding-left: 20px;
  border: 1px solid #E6EAEE;
  border-radius: 4px 4px 0 0;
  background: linear-gradient(0deg, #F4F7FA 0%, #FFFFFF 100%);
  background-color: #337ab7 !important;
  display:flex;
  flex-wrap: wrap;
  align-items: center;
  color: rgba(49,49,49,0.8);
}

.TableHead__SearchBox {
  margin-right: 16px;
}

.TableHead__Button--rightSpace {
  margin-right: 8px;
  background-color: #337ab7 !important;
}

.TableHead__hspacefill {
  margin-left: auto;
}

.TableHead__strut {
  margin-left: 16px;
}
