.ToggleButton_Outer {
  display:flex;
  user-select: none;
  -moz-user-select: none;
}

.ToggleButton {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  background-color: #FFFFFF;
}
.ToggleButton_Rounded {
  border-radius: 18px;
  border: 1px solid #4758BE;
}

.ToggleButton_disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.ToggleButton_Button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8em 1em;
  min-height: 48px;
  font-weight: 500;
  max-width: 16em
}
.ToggleButton_Button_Unrounded {
  border: 1px solid #4758BE;
}
.ToggleButton_Button_Rounded{
  line-height: 10px;
  border-radius: 14px;
  margin:4px;
}

.ToggleButton:hover {
  cursor:pointer;
}
.ToggleButton_disabled:hover {
  cursor:not-allowed;
}

.ToggleButton_selected {
  background-color: #4758BE;

  color: #FFFFFF;
}

.ToggleButton_Left_Unrounded {
  border-radius: 4px 0 0 4px;
}

.ToggleButton_Left_Rounded {
  border-radius: 14px;
}

.ToggleButton_Right_Unrounded {
  border-radius: 0px 4px 4px 0px;
}

.ToggleButton_Right_Rounded {
  border-radius: 14px;
}

.ToggleButton_unselected {
  background-color: #FFFFFF;

  color: #364760;
}
