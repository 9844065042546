.WWA__Table {
  display: grid;
}

.WWA__Row {
  height: 46px;
  background-color: #FFFFFF;
  color: #354052;
}

.WWA__Row > td {
  vertical-align: middle !important;
}

.WWA__Row--Header {
  height: 36px;
  border: 1px solid #E6EAEE;
  background-color: #F5F8FA;
}

.WWA__Row--selected {
  height: 46px;
  background-color: #FBFBFC;
  color: #354052;
}

.WWA__Row--selected > td {
  vertical-align: middle !important;
}

.WWA__Row--checked > td {
  background-color: rgba(38,155,243,0.1);
  vertical-align: middle !important;
}

.WWA__Table--Header {
  height: 36px;
  border: 1px solid #E6EAEE;
  background-color: #F5F8FA;
}

.WWA__Table--Header--clickable {
  cursor: pointer;
}

.WWA__Table--Header > th {
  height: 17px;
  color: #7F8FA4;
  font-size: 14px;
  line-height: 17px;
}

.WWA__Table--rightAlign {
  text-align: right;
}

.WWA__Table--centerAlign {
  text-align: center;
}
