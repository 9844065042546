@import "common/css/_breakpoints.scss";

.ThreatTypeSelector_ThreatTypeCheckboxes {
  display: grid;
  grid-template-areas: "tts-all tts-all" "tts-left tts-right";
}

.ThreatTypeSelector_all {
  grid-area: tts-all;
}

.ThreatTypeSelector_left {
  grid-area: tts-left;
}

.ThreatTypeSelector_right {
  grid-area: tts-right;
}

@media screen and (max-width: $break-mobile) {
  .ThreatTypeSelector_ThreatTypeCheckboxes {
    grid-template-areas: "tts-all" "tts-left" "tts-right";
  }
}
