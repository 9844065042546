.AssignmentList__outer {
  margin: 10px 30px 0px;
}

.AssignmentList__nodata {
  text-align: center;
}

.AssignmentList_GreaterThan {
  align-self: center;
  opacity: 0.6;
  padding-left: 6px;
  width: 20px;
  cursor: pointer;
}
