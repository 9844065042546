body {
  margin: 0;
  padding: 0;
  background-color: #EFF4F8;
  height: auto;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px; 
}

a {
  color: #337ab7;
}

.btn {
  padding: .3rem .7rem;
  font-size: 0.9rem;
}

.btn-primary {
  background-color: #337ab7;
}
.btn-primary:hover {
  background-color: #286090;
  border-color: #204d74;
}

.table td {
  padding: 0.5rem;
}
