
.ResultWithDelete__Tag {
  padding: 6px;
  border: 1px solid #4758BE;
  border-radius: 3px;
  background-color: rgba(71,88,190,0.05);
  margin-right: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
  cursor: pointer;
}

.ResultWithDelete__Tag {
  display: flex;
  align-items: center;
}

.ResultWithDelete__Tag__Sitename {
  color: #4758BE;
  margin-left: 8px;
  margin-right: 12px;
  line-height:normal;
  font-family: 'FontAwesome', 'Rubik', sans-serif;
}

.ResultWithDelete__img {
  margin: auto 8px auto auto;
}


.ResultPool__ResultItem {
  display:flex;
  flex-wrap: wrap;
}


