.ReportSettings__outer {
  display: flex;
  justify-content: center;
  background-color: #fbfbfc;
}

.ReportSettings__tab {
  margin: 3ex 0;
  background-color: #ffffff;
  color: #7f8fa4;
  /*font-weight: 500;*/
}

.ReportSettings__tabtitle {
  font-size: 16px;
}

.ReportSettings__table--header {
  font-weight: 500;
}

.ReportSettings__button {
  margin-right: 20px;
}

.ReportSettings__table {
  border: 1px solid #e6eaee;
}

.ReportSettings__tableBody {
  color: #7f8fa4;
}

.ReportSettings__footer {
  margin: 3ex 0;
}

.ReportSettings__ShowLogoCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  min-height: 30px;
}

.ReportSettings__LogoThumbnail img {
  max-height: 28px;
  margin-left: 0.3em;
  margin-right: 0.3em;
  border: 1px solid #e6eaee;
}

.ReportSettings__HelpModal{
  display: flex;
  justify-content: flex-end;
}
.ReportSettings__Help {
  border: 1px solid #e6eaee;
  padding: 6px;
  margin: 4px;
  cursor: pointer;
}

.ReportSettings__CustomReportLabel {
  opacity: 0.6;
  color: #364760;
  font-size: 14px;
  font-weight: 500;
}

.ReportSettings_Buttons {
  display: flex;
  justify-content: space-between;
}
