.SiteSel__select__by--outer {
  width: 162px;
}

.SiteSel__Dropdowns {
  display: flex;
  grid-template-columns: 1fr 1fr;
}

.SiteSel__citysearch {
  display:flex;
}

.SiteSel__option {
  padding: 12px;
}
.SiteSel__option:hover {
  background-color:#DFE3E9;
}
.SiteSel__option--none {
  display: none;
}

.SiteSel__input__loc {
  width: 240px;
}

.SiteSel__input {
  height: 36px;
  padding-left: 36px;
  border: 1px solid #CED0DA;
  border-radius: 4px;
  background-color: #FFFFFF;
  background-image: url('../../../images/dashboard/w_search.svg');
  background-repeat: no-repeat;
  background-position: left 8px center;
  outline: none;
}
.SiteSel__input__site__outer {
  display:flex;
  flex-direction: column;
  width: 420px;
}
.SiteSel__Select__site {
  width: inherit;
  border: 1px solid #CED0DA;
  border-radius: 4px;
  background-color: #FFFFFF;
  color: rgba(49,49,49,0.8);

  -webkit-appearance:button;
     -moz-appearance:button;
          appearance:button;

  margin: 0px auto 12px 0px;
  overflow: hidden;
  overflow-y: auto;
}
.SiteSel__select--outer {
  width: inherit;
}
#sselopt {
  padding: 12px;
}

.redborder {
  border: 1px solid rgba(249,0,0,0.5);
}

.SiteSel__nositefound {
  height: 40px;
  width: 420px;
  color: rgba(49,49,49,0.8);
  border: 1px solid #DFE3E9;
  background-color: #FFFFFF;
  padding-top: 10px;
  padding-left: 16px;
  margin-top: 4px;
}

.SiteSel__Toggle {
  margin-bottom: 1em;
}
