.ThreatFilter {
  padding: 16px;

  border: 1px solid #E6EAEE;
  background: linear-gradient(0deg, #F4F7FA 0%, #FFFFFF 100%);
}

.ThreatFilter_Top {
  display: flex;
  align-items: center;
  color: rgba(49,49,49,0.8);
  font-size: 16px;
  line-height: 24px;
}

.ThreatFilter_Head {
}

.ThreatFilter_Back {
  margin-left: auto;
  font-size: x-large;
  opacity: 0.6;
  font-weight: 500;
}

.ThreatFilter_Subhead {
  opacity: 0.6;
  color: #364760;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-top: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
}

.ThreatFilter_VFill {
  margin-top: 20px;
}

.ThreatFilter_Buttons{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.ThreatFilter_Button {
  height: 48px;
  min-width: 109px;
  margin-right: 20px;
  margin-top: 1em;
}

.ThreatFilter_Button_Apply {
  border: 1px solid #4F63D6;
  border-radius: 4px;
  background: linear-gradient(0deg, #5368E0 0%, #5B72F5 100%);
  color: #FFFFFF;
}

.ThreatFilter_Button_Save {
  border: 1px solid #CED0DA;
  border-radius: 4px;
  background: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  font-weight: 500;
  color: #7F8FA4;
}

.ThreatFilter_Button_Cancel {
  color: #7F8FA4;
  background-color: transparent;
  font-weight: 500;
  border: none;
}

.SaveThreatFilter__item {
  margin-bottom: 2ex;
}

.SaveThreatFilter__warn {
  color: red;
}

.SaveThreatFilter--spacing {
  margin-bottom: 4ex;
}

.PresetThreatFilter__header {
  opacity: 0.6;
  color: #364760;
  font-size: 14px;
  font-weight: 500;
  background-color: #f0f0f0;
  padding: 12px;
}

.PresetThreatFilter__item {
  display: flex;
  align-items: baseline;
  border-top: 1px solid #EFF4F8;
  border-top: 1px solid #EFF4F8;
  padding: 8px 0px 8px 12px;
}

.PresetThreatFilter__infoName {
  opacity: 0.6;
  color: #364760;
  font-weight: 500;
}
