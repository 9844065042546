.Paging {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 4px 4px;
  background-color: #FFFFFF;
  font-size: 10px;
}

.PagingControl_Paging {
  height: 48px;
  min-height: 48px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 0 0 4px 4px;
  background-color: #FFFFFF;
  font-size: 10px;
}


.PagingControl__PageNumbers {
  font-size: 18px;
  color: #7F8FA4;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
}

.PagingControl__PageNumbers:hover > img {
  border: solid 1px #b1bccc;
}

.PagingControl__PageNumbers> img {
  width: 36px;
  height: 36px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #d0d3d6;
}

.Paging_Medium {
  font-size: 14px;
}

.Paging_input {
  width: 44px;
  height: 36px;
  font-size: 14px;
  color: #7F8FA4;
  text-align: center;
}

.PagingControl_PagingControl > img {
  width: 36px;
  height: 36px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #d0d3d6;
}

.PagingControl_PagingControl_Disabled > img {
  width: 36px;
  height: 36px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #d0d3d6;
}

.PagingControl_PagingControl:hover > img {
  border: solid 1px #b1bccc;
}

.PagingControl_PagingControl:active > img {
  background-color: #e6eaee;
}

.PagingControl_PagingControl_Disabled {
  font-size: 18px;
  color: #7F8FA4;

  border: solid 1px #e6eaee;
  opacity: 0.6;
}

.PagingControl_PagingControl {
  font-size: 18px;
  color: #7F8FA4;
  user-select: none;
  -moz-user-select: none;
  cursor: pointer;
}

