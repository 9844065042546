
.FilterSlider_Slider {
  width: 100%;
  height: 40px;
  margin: 1em 2em;
}

.FilterSlider__outer {
  display:flex;
  margin-right: 1em;
}

.FilterSlider__value {
  width: 4em;
  opacity: 0.6;
  color: rgb(53, 64, 82);
  text-align: right;
  font-size: 1rem;
}
