@import 'common/css/_breakpoints.scss';

.App {
  height: 100vh;
}

/* override react-bootstrap anchor in link color */
#downloadReportsZipLink {
  color: #FFF;
}
#downloadReportsZipLink:hover {
  color: #FFF;
}

/*
  iOS will zoom the viewport for when input fields have a font-size of less than 16px
  but will not zoom back; to prevent this, set all input boxes to 16px
*/
@media screen and (max-width: $break-mobile) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
}

