.SubscriptionSelector_SubscriptionCheckboxes {
  display: flex;
  justify-content: space-between;
}


.SubscriptionSelector_Label {
  color: #7F8FA4;
  font-weight: 500;
}

.SubscriptionSelector_AddressXGrid {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr 1fr 1fr;
  grid-column-gap: 1em;
  margin: 1em;
}

.SubscriptionSelector_AddressXGrid_Row {
  color: #354052;
}
